<template>
  <!-- 我的邀请 -->
  <div class="container">
    <div class="content">
      <div class="content-item" v-for="(item, index) in list" :key="index">
        <div class="text-item" :class="index === 0 ? 'no-margin' : ''">
          <div class="avatar-box">
            <van-image round width="30px" height="30px" :src="item.avatar"/>
            <div class="nickName">
              {{ item.nickName }}
            </div>
          </div>
          <div class="register-box">
            <div class="register-time"> 注册时间：{{ item.createTimeStr }} </div>
          </div>
        </div>
        <div class="detail-items">
          <div class="left">
            <div class="detail-item">
              <div class="detail-item-name"> 参与活动 </div>
              <div class="detail-item-text"> {{item.inviteTypeStr}} </div>
            </div>
            <div class="detail-item">
              <div class="detail-item-name"> 参与资格 </div>
              <div class="detail-item-text"> {{item.isPlace?'已':'未'}}符合 </div>
            </div>
          </div>
          <div style="display: flex; align-items: center;">
            <div class="gap"></div>
            <div class="right" >
              <div class="detail-item">
                <div class="detail-item-name"> 结算状态 </div>
                <div class="detail-item-text"> {{item.isSettlement?'已':'未'}}结算 </div>
              </div>
              <div class="detail-item">
                <div class="detail-item-name"> 奖金金额 </div>
                <div class="detail-item-text"> {{item.isSettlement?item.settlementAmount:item.rewardAmount}} 元</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="list.length==0" style="margin-top: 100px;">
        <van-empty description="快去邀请吧" />
      </div>
    </div>
  </div>
</template>
<script>
  import $inviteApi from "@/api/invite.js";
  export default {
    data() {
      return {
        list: [],
        searchForm: {
          pageIndex: 1,
          pageSize: 1000,
        },
        head: {
          token: '',
          agent: ''
        }
      };
    },

    created() {
      //app 获取token
      getToken.postMessage('')
    },
    mounted() {
      //获取邀请码，APP主动调用X
      window['getTokenX'] = header => {
        if (header){
          this.head = JSON.parse(header)
          this.getList()
        }
      }
    },
    methods: {
      getList() {
        $inviteApi.myInviteList(this.searchForm, this.head).then(({ data }) => {
          // let tmp = data
          // for(let i=0;  i<30;i++){
          //   tmp.push(data[0])
          // }
          this.list = data
        })
      },
    },
  };
</script>

<style lang="scss" scoped>
  .container {

    background: #f9f9f9;
    // background: #000;
    // background-color: #FFFFFF;
    min-height: 100vh;

    padding: 10px 15px;

    .content {
      // padding-: 20rpx 0;
      background-color: #f9f9f9;
      .content-item {
        background: #ffffff;
        border-radius: 8px;
        margin: 10px 0 0;
        padding: 10px;
      }

      // background: #000;
      .text-item {
        width: 100%;
        height: 37.5px;
        //   padding: 13rpx 40rpx;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        // background-color: #0040D0;
        // background-color: #fff8dc;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;

        // align-items: center;
        .avatar-box {
          display: flex;
          align-items: center;

          .nickName {
            margin-left: 5px;
            font-size: 12.5px;
            font-family: PingFang SC;
            font-weight: 700;
            color: #333333;
          }
        }

        .register-box {
          font-size: 11px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          // justify-content: space-between;
          justify-content: center;
          // align-items: flex-end;
          .order-info {
            margin-bottom: 10px;
          }
        }
      }
      .no-margin {
        // margin-top: 0;
      }
      .detail-items {
        display: flex;
        align-items: center;
        .left {
          flex: 0.9;
        }
        .gap {
          width: 1px;
          height: 40px;
          background: #d2d2d2;
          margin-right: 5px;
        }
        .detail-item {
          display: flex;
          .detail-item-name {
            font-size: 11px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            margin-right: 10px;
          }
          .detail-item-text {
            font-size: 11px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ffa164;
          }
        }
      }
    }
  }
</style>
