import request from '@/utils/request'

export default {
	// 获取我邀请的好友列表
	myInviteList(params, headers) {
		return request({
			url: '/user/invite/myInviteList',
			method: 'get',
			params,
			headers: headers
		})
	},
}
